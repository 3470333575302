import { useEffect } from 'react'
import NProgress from 'nprogress'
import { Box, CircularProgress } from '@mui/material'

function SuspenseLoader() {
  useEffect(() => {
    NProgress.start()

    return () => {
      NProgress.done()
    }
  }, [])

  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
      <CircularProgress size={64} disableShrink thickness={3} />
    </Box>
  )
}

export default SuspenseLoader
