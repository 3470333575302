import { Box, List, Menu, MenuItem } from '@mui/material'
import { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

function HeaderMenu() {
  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  // const handleOpen = (): void => {
  //   setOpen(true);
  // };

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <>
      <Box>
        <List disablePadding component={Box} display="flex"></List>
      </Box>
      <Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>
        <MenuItem component={NavLink} to="/overview">
          Overview
        </MenuItem>
        <MenuItem component={NavLink} to="/components/tabs">
          Tabs
        </MenuItem>
        <MenuItem component={NavLink} to="/components/cards">
          Cards
        </MenuItem>
        <MenuItem component={NavLink} to="/components/modals">
          Modals
        </MenuItem>
      </Menu>
    </>
  )
}

export default HeaderMenu
