import { useContext } from 'react'
import { Box, Stack, Divider, IconButton, Tooltip, styled } from '@mui/material'
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'
import { SidebarContext } from 'src/contexts/SidebarContext'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import HeaderUserbox from './Userbox'
import HeaderMenu from './Menu'

type HeaderProps = {
  isScreenSmall?: boolean
}

export const HeaderWrapper = styled(Box)(() => ({
  'position': 'relative',
  'minHeight': '90px',

  '.inner-wrap': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    backdropFilter: 'blur(3px)',
    padding: '0.5rem 2.5rem',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
  },
}))

function Header({ isScreenSmall }: HeaderProps) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)
  // const theme = useTheme()
  return (
    <HeaderWrapper>
      <Box className="inner-wrap">
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} alignItems="center" spacing={2}>
          <HeaderMenu />
        </Stack>
        <Box display="flex" alignItems="center">
          <HeaderUserbox />
          {isScreenSmall && (
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? <MenuTwoToneIcon fontSize="small" /> : <CloseTwoToneIcon fontSize="small" />}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </HeaderWrapper>
  )
}

export default Header
