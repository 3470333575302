import { useContext } from 'react'
import { SidebarContext } from 'src/contexts/SidebarContext'

import { Box, Drawer, styled, Divider, useTheme } from '@mui/material'

import SidebarMenu from './SidebarMenu'
import Logo from 'src/components/LogoSign'

export const SidebarWrapper = styled(Box)(({ theme }) => ({
  'position': 'fixed',
  'top': 0,
  'maxWidth': '300px',
  'width': '100%',
  'height': '100vh',
  'display': 'inline-block',
  'background': theme.colors.gradients.primary,
  'zIndex': 1,

  '.MuiList-root': {
    'padding': '1.25rem',
    'borderBottom': '1px solid',
    'borderColor': theme.colors.alpha.white[50],

    '&:last-child': {
      borderBottom: 'none',
    },
  },
}))

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)
  const closeSidebar = () => toggleSidebar()
  const theme = useTheme()

  return (
    <>
      <SidebarWrapper>
        <Box sx={{ padding: '2rem' }}>
          <Logo />
        </Box>
        <Divider />
        <SidebarMenu />
      </SidebarWrapper>
      <Drawer anchor={theme.direction === 'rtl' ? 'right' : 'left'} open={sidebarToggle} onClose={closeSidebar} variant="temporary" elevation={9}>
        <SidebarWrapper>
          <Box sx={{ padding: '2rem' }}>
            <Logo />
          </Box>
          <Divider />
          <SidebarMenu />
        </SidebarWrapper>
      </Drawer>
    </>
  )
}

export default Sidebar
