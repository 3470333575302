import { ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { PureLightTheme } from './schemes/PureLightTheme'

const themeLookup = {
  PureLightTheme,
}

const ThemeProvider: React.FC = ({ children }) => {
  const theme = themeLookup['PureLightTheme']

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider
