const storagePrefix = 'dlt_admin'

const storage = {
  getToken(): string | null {
    return JSON.parse(localStorage.getItem(`${storagePrefix}_token`))
  },
  setToken(token: string) {
    localStorage.setItem(`${storagePrefix}_token`, JSON.stringify(token))
  },
  clearToken() {
    localStorage.removeItem(`${storagePrefix}_token`)
  },
}

export default storage
