import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { paths } from 'src/configs/paths'

const LogoSign = () => {
  return (
    <Link to={paths.home}>
      <Box component="img" src="/static/images/logo/dlt-alert.png" alt="logo" />
    </Link>
  )
}

export default LogoSign
