import { FC, ReactNode } from 'react'
import { Box, styled } from '@mui/material'
import { Outlet } from 'react-router-dom'

import Sidebar from './Sidebar'
import Header from './Header'

interface SidebarLayoutProps {
  children?: ReactNode
}

export const MainContainer = styled(Box)(() => ({
  '.content': {
    maxWidth: 'calc(100% - 300px)',
    padding: '0 3rem',
    marginLeft: 'auto',
    width: '100%',
  },
}))

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  return (
    <MainContainer>
      <Header />
      <Box display="flex">
        <Sidebar />
        <Box className="content ">
          <Outlet />
        </Box>
      </Box>
    </MainContainer>
  )
}

export default SidebarLayout
