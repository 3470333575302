import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useRoutes } from 'react-router-dom'
import { Suspense } from 'react'
import SuspenseLoader from 'src/components/SuspenseLoader'
import ThemeProvider from 'src/theme/ThemeProvider'
import routes from 'src/router'
import './index.css'

const App = () => {
  const content = useRoutes(routes)

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Suspense fallback={<SuspenseLoader />}>{content}</Suspense>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
export default App
