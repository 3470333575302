import { Chain, configureChains, createClient } from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { publicProvider } from 'wagmi/providers/public'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { polygon } from 'wagmi/chains'
import { PLOYGON_AMOY_RPC_URL } from 'src/configs/constants'

export const polygonAmoy = {
  id: 80_002,
  name: 'Polygon Amoy',
  network: 'polygon',
  nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  rpcUrls: {
    public: { http: ['https://polygon-amoy.drpc.org'] },
    default: { http: ['https://polygon-amoy.drpc.org'] },
  },
  blockExplorers: {
    default: {
      name: 'OK LINK',
      url: 'https://www.oklink.com/amoy',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 25770160,
    },
  },
} as const as Chain
export const { chains, provider, webSocketProvider } = configureChains(
  [polygonAmoy, polygon],
  [
    publicProvider(),
    jsonRpcProvider({
      rpc: () => ({
        http: PLOYGON_AMOY_RPC_URL || '',
      }),
    }),
  ]
)

export const wagmiClient = createClient({
  autoConnect: false,
  connectors: [new MetaMaskConnector({ chains })],
  provider,
  webSocketProvider,
})
