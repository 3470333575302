export const endpoints = {
  ADMIN_LOGIN: '/admin/login',
  ADMIN_FORGOT_PASSWORD: '/admin/forgot/password',
  ADMIN_RESET_PASSWORD: '/admin/reset/password',
  ADMIN_CHANGE_PASSWORD: '/admin/change/password',
  ADMIN_PROFILE: '/admin/profile',
  ADD_ADMIN: '/admin/add/admin',
  ADMIN_SET_PASSWORD: 'admin/set/password',
  ADMIN_BLOCK: '/admin/block',
  ADMIN_ROLE_CHANGED: 'admin/update',
  ADMIN_DELETE: '/admin/delete',
  ADMIN_LIST: '/admin/list',
  THREATS_STATUS_UPDATE: 'dlt/threats/update/status',
  THREATS: 'dlt/threats/field/listing',
  TWO_FA_TOGGLE: '/admin/twofa/toggle',
  TWO_FA_DETAILS: '/admin/twofa/detail',
  TWO_FA_LOGIN: '/admin/login/verification',
  THREATS_ADMIN_LISTING: '/dlt/threats/listing',
  ADMIN_USER_LIST: '/admin/user/list',
  INSURANCE_COMPANIES_LISTING: '/dlt/insurance/companies',
  ADD_INSURANCE_COMPANIES: '/dlt/insurance/companies/add',
  ACTIVATE_INSURANCE_COMPANIES: '/dlt/insurance/companies/deactivate/toggle?',
  INSURANCE_COMPANIES_ADMINS: '/dlt/merchants',
  BLOCK_INSURANCE_COMPANIES_ADMINS: '/dlt/merchants/block/toggle',
  ADD_INSURANCE_COMPANIES_ADMIN: '/dlt/merchants/add',
  ADD_INSURANCE_PLANS: '/dlt/insurance/plans',
  ADD_INSURANCE_POLICIES: '/dlt/insurance/policies',
  COMPANY_THREATS: '/dlt/threats',
  THREATS_DETAIL: '/dlt/threats/detail',
  ADD_COMMENTS: 'dlt/comments/add',
  COMMENT_LISTING: 'dlt/comments/',
  INSURANCE_REQUESTS: '/dlt/insurance/requests/',
  POLICY_PARAMETER: 'dlt/policy/parameters',
  POLICY_PARAMETER_TOGGLE: 'dlt/policy/parameters/disable/toggle',
  RESEND_EMAIL: '/admin/registration/email/resend',
  ADD_ADMIN_USER: 'admin/add/user',
  UPDATE_COMMENT: 'dlt/comments/update',
  REMOVE_COMMENT: 'dlt/comments/remove',
  GET_DOCUMENT: 'dlt/insurance/plans/documents',
  GETTHREATCHAINTRANSACTION: 'dlt/threats/chain/hash',
  GET_SCORECARD: 'dlt/domain/security/score/',
  CHECK_SCORECARD: 'dlt/domain/security/score/request/settel/toggle',
  RESEND_SET_PASSWORD_TO_USER: 'admin/resend/user/set/password/email',
  RESEND_SET_PASSWORD_TO_MERCHANT: 'dlt/merchants/resend/set/password/email',
  RESEND_SET_PASSWORD_TO_ADMIN: 'admin/resend/admin/set/password/email',
  ADD_CATEGORY: 'category/add',
  UPDATE_CATEGORY: 'category/update',
  REMOVE_CATEGORY: 'category/remove',
  CATEGORY_LISTING: 'category',
  ADD_BLOG: 'admin/blog/add',
  UPDATE_BLOG: 'admin/blog/update',
  REMOVE_BLOG: 'admin/blog/remove',
  BLOG_LISTING: 'admin/blog',
  TOGGLE_BLOG: 'admin/blog/publish/toggle',
  GET_USER_REGISTRATION_REQUEST: 'admin/registration/request/list',
  SERVICES_LISTING: 'user/services/list',
  COVE_THREAT_LISITING: 'dlt/cove/threats/listing',
  COVE_THREAT_DETAILS: 'dlt/cove/threats/detail',
  NSIGHT_THREAT_LISITING: 'dlt/patch/threats/listing',
  NSIGHT_THREAT_DETAILS: 'dlt/patch/threats/detail',
  EDITDELETE_AFFILIATEID: 'dlt/merchants/edit/affiliate',
  RESEND_USER_PAYMENT: 'admin/resend/user/payment/required/email',
  COVE_ACTIVATION: 'admin/activate/user/cove',
  SENTINEL_ACTIVATION: 'admin/activate/user/sentinel',
  NSIGHT_ACTIVATION: 'admin/activate/user/patch',
  BLOCK_USER: 'admin/user/status',
  ToggleInsurancePlans: 'insurance/plans/deactivate/toggle/admin',
  ADMIN_DEACTIVATE_SERVICE: 'admin/deactivate/service',
  MISP_API: '/misp',
}
