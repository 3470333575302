import { useEffect, useRef, useState } from 'react'

import { NavLink, useLocation } from 'react-router-dom'

import { Box, Button, Divider, Hidden, lighten, ListItem, Popover, Typography } from '@mui/material'

import { styled } from '@mui/material/styles'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import useAuth from 'src/hooks/useAuth'
import { paths } from 'src/configs/paths'

function HeaderUserbox() {
  const { logout, user } = useAuth()

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)
  const { pathname } = useLocation()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // close popover on route change
  useEffect(() => {
    handleClose()
  }, [pathname])

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <UserBoxLabel variant="h4" sx={{ mb: 1, cursor: 'pointer' }}>
          {user?.firstName || '-'}
        </UserBoxLabel>
        <Box display="flex" alignItems="center">
          <Hidden mdDown>
            <UserBoxDescription variant="body2">{user.role || '-'}</UserBoxDescription>
          </Hidden>
          <Hidden smDown>
            <ExpandMoreTwoToneIcon />
          </Hidden>
        </Box>
      </UserBoxButton>
      <PopoverContainer
        className="header-dropdown"
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box>
          <UserBoxLabel variant="h4" sx={{ mb: 1 }}>
            {user?.firstName || '-'}
          </UserBoxLabel>
          <UserBoxDescription variant="body2">{user.role || '-'}</UserBoxDescription>
        </Box>
        <Divider />
        <ListItem button to={paths.profile} component={NavLink}>
          <AccountBoxTwoToneIcon fontSize="small" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            My Profile
          </Typography>
        </ListItem>
        <Divider />
        <Button color="primary" fullWidth onClick={logout}>
          <LockOpenTwoToneIcon />
          <Typography variant="body2" sx={{ ml: 1 }}>
            Sign out
          </Typography>
        </Button>
      </PopoverContainer>
    </>
  )
}

const PopoverContainer = styled(Popover)(`
  padding: 1rem;

  button {
    display: flex;
  }
`)

const UserBoxButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}))

const UserBoxLabel = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.secondary.main,
  display: 'block',
}))

const UserBoxDescription = styled(Typography)(({ theme }) => ({
  color: lighten(theme.palette.secondary.main, 0.5),
}))

export default HeaderUserbox
