import { lazy } from 'react'
import { Navigate, useLocation, type RouteObject } from 'react-router-dom'

import SidebarLayout from 'src/layouts/SidebarLayout'
import BaseLayout from 'src/layouts/BaseLayout'
import useAuth from 'src/hooks/useAuth'
import { paths } from './configs/paths'

// General
const MyProfile = lazy(() => import('src/features/profile/pages/MyProfile'))
const Threats = lazy(() => import('src/features/threats/pages/Threats'))
const Admins = lazy(() => import('src/features/admins/pages/Admins'))
const AddAdmin = lazy(() => import('src/features/admins/pages/AddAdmin'))
const TwoFactorAuthentication = lazy(() => import('src/features/profile/pages/TwoFactorAuthentication'))
const User = lazy(() => import('src/features/user/pages/UsersListing'))
const AddUser = lazy(() => import('src/features/user/pages/AddUser'))

const InsuranceCompanies = lazy(() => import('src/features/insuranceCompanies/pages/InsuranceCompanies'))
const AddInsuranceCompany = lazy(() => import('src/features/insuranceCompanies/pages/AddInsuranceCompany'))
const Details = lazy(() => import('src/features/insuranceCompanies/pages/CompanyDetails'))
const AddInsuranceCompanyAdmin = lazy(() => import('src/features/insuranceCompanies/pages/AddInsuranceCompanyAdmin'))
const Incidents = lazy(() => import('src/features/dashboards/pages/Incidents'))
const PolicyParameters = lazy(() => import('src/features/policyParameters/pages/PolicyParameters'))
const Blog = lazy(() => import('src/features/Blog/pages/AddBlog'))
const EditBlog = lazy(() => import('src/features/Blog/pages/EditBlog'))

// Auth
const Login = lazy(() => import('src/features/auth/pages/Login'))
const Register = lazy(() => import('src/features/auth/pages/Register'))
const ForgotPassword = lazy(() => import('src/features/auth/pages/ForgotPassword'))
const ResetPassword = lazy(() => import('src/features/auth/pages/ResetPassword'))

const SetPassword = lazy(() => import('src/features/admins/pages/SetPassword'))
const ResendLink = lazy(() => import('src/features/admins/pages/ResendLink'))

// Status
const Status404 = lazy(() => import('src/features/misc/pages/Status404'))

const Protected = ({ children }) => {
  const { user } = useAuth()
  const { pathname } = useLocation()

  if (!user) {
    return <Navigate to={paths.login} state={{ from: pathname }} />
  }
  return children
}

const routes: RouteObject[] = [
  {
    path: paths.app,
    element: (
      <Protected>
        <SidebarLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <User />,
      },
      {
        path: 'two-factor-authentication',
        element: <TwoFactorAuthentication />,
      },
      {
        path: 'profile',
        element: <MyProfile />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: paths.home,
    element: (
      <Protected>
        <SidebarLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <User />,
      },
      {
        path: 'add-user',
        element: <AddUser />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },

  {
    path: paths.policyParameters,
    element: (
      <Protected>
        <SidebarLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <PolicyParameters />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: paths.Blogs,
    element: (
      <Protected>
        <SidebarLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <Blog />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: paths.editBlogs,
    element: (
      <Protected>
        <SidebarLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <EditBlog />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: paths.incidents,
    element: (
      <Protected>
        <SidebarLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <Incidents />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: paths.admin,
    element: (
      <Protected>
        <SidebarLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <Admins />,
      },
      {
        path: 'add-admin',
        element: <AddAdmin />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: paths.insuranceCompanies,
    element: (
      <Protected>
        <SidebarLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <InsuranceCompanies />,
      },
      {
        path: 'details',
        element: <Details />,
      },
      {
        path: 'add-insurance-company',
        element: <AddInsuranceCompany />,
      },
      {
        path: 'add-admin',
        element: <AddInsuranceCompanyAdmin />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: paths.threats,
    element: (
      <Protected>
        <SidebarLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <Threats />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: '/',
    element: <Navigate to="/user" />,
  },
  {
    path: '/auth',
    element: <BaseLayout />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'set-password',
        element: <SetPassword />,
      },
      {
        path: 'resend-verification-link',
        element: <ResendLink />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: '*',
    element: <Status404 />,
  },
]

export default routes
