import { alpha, createTheme, lighten, darken } from '@mui/material'
import '@mui/lab/themeAugmentation'

const themeColors = {
  primary: '#B79313',
  primaryLight: '#FF9E00',
  secondary: '#6E759F',
  success: '#27AE60',
  successBg: '#27AE6033',
  warning: '#E2B93B',
  warningBg: '#E2B93B33',
  error: '#EB5757',
  errorBg: '#EB575733',
  info: '#2F80ED',
  infoBg: '#2F80ED33',
  black: '#000000',
  black1: '#1D1D1D',
  black2: '#282828',
  white: '#FFFFFF',
  white1: '#F1F5F9',
  primaryAlt: '#0D1023',
  grey: '#828282',
  grey1: '#524F4F',
  inputBg: '#E0E0E0',
  blueDark: '#1F2545',
  dropDownBg: '#32385b',
  beigeBackGround: ' rgba(235, 87, 87, 0.20)',
}
const colors = {
  gradients: {
    primary: 'linear-gradient( 90.18deg, #32385B 0%, #1A1F41 100%)',
    card: 'linear-gradient( 142.08deg, rgba(255, 255, 255, 0.7) -5.03%, rgba(188, 188, 188, 0.85) 21.3%, rgba(13, 16, 35, 0.2) 49.04%, rgba(187, 187, 187, 0.5) 70.19%)',
    modal: 'linear-gradient( 153.32deg, rgba(255, 255, 255, 0.3) -65.62%, rgba(255, 255, 255, 0.1) 83.28%)',
    primaryLine: 'linear-gradient(300.96deg, rgba(183, 147, 19, 0.3) 18.75%, #B79313 81.54%)',
    blue: 'linear-gradient(153.32deg, rgba(255, 255, 255, 0.3) -65.62%, rgba(255, 255, 255, 0.1) 83.28%)',
    primaryOverlay: 'linear-gradient(90.18deg, rgba(50, 56, 91, 0.57) 0%, rgba(26, 31, 65, 0.57) 100%)',
  },
  shadows: {
    success: '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
    error: '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
    info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
    primary: '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
    warning: '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
    card: '0px 9px 16px rgba(50, 56, 91, .18), 0px 2px 2px rgba(50, 56, 91, 0.32)',
    cardSm: '0px 2px 3px rgba(50, 56, 91, .18), 0px 1px 1px rgba(50, 56, 91, 0.32)',
    cardLg: '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)',
  },
  layout: {
    general: {
      bodyBg: '#f2f5f9',
    },
    sidebar: {
      background: themeColors.primaryAlt,
      textColor: themeColors.white,
      dividerBg: '#f2f5f9',
      menuItemColor: '#242E6F',
      menuItemColorActive: themeColors.primary,
      menuItemBg: themeColors.white,
      menuItemBgActive: '#f2f5f9',
      menuItemIconColor: lighten(themeColors.secondary, 0.3),
      menuItemIconColorActive: themeColors.primary,
      menuItemHeadingColor: darken(themeColors.secondary, 0.3),
    },
  },
  alpha: {
    white: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white,
    },
    trueWhite: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white,
    },
    black: {
      5: alpha(themeColors.black, 0.02),
      10: alpha(themeColors.black, 0.1),
      30: alpha(themeColors.black, 0.3),
      50: alpha(themeColors.black, 0.5),
      70: alpha(themeColors.black, 0.7),
      100: themeColors.black,
    },
  },
  secondary: {
    lighter: lighten(themeColors.secondary, 0.85),
    light: lighten(themeColors.secondary, 0.25),
    main: themeColors.secondary,
    dark: darken(themeColors.secondary, 0.2),
  },
  primary: {
    lighter: lighten(themeColors.primary, 0.85),
    light: lighten(themeColors.primary, 0.3),
    main: themeColors.primary,
    dark: darken(themeColors.primary, 0.2),
  },
  success: {
    lighter: lighten(themeColors.success, 0.85),
    light: lighten(themeColors.success, 0.3),
    main: themeColors.success,
    bg: themeColors.successBg,
    dark: darken(themeColors.success, 0.2),
  },
  warning: {
    lighter: lighten(themeColors.warning, 0.85),
    light: lighten(themeColors.warning, 0.3),
    main: themeColors.warning,
    bg: themeColors.warningBg,
    dark: darken(themeColors.warning, 0.2),
  },
  error: {
    lighter: lighten(themeColors.error, 0.85),
    light: lighten(themeColors.error, 0.3),
    main: themeColors.error,
    bg: themeColors.errorBg,
    dark: darken(themeColors.error, 0.2),
  },
  info: {
    lighter: lighten(themeColors.info, 0.85),
    light: lighten(themeColors.info, 0.3),
    main: themeColors.info,
    bg: themeColors.infoBg,
    dark: darken(themeColors.info, 0.2),
  },
  inputBg: {
    100: themeColors.inputBg,
  },
  darkBg: {
    100: themeColors.blueDark,
  },
  border: {
    100: themeColors.grey,
  },
  borderTable: {
    100: themeColors.grey1,
  },
  dropdownBorder: {
    100: themeColors.white1,
  },
}

export const PureLightTheme = createTheme({
  colors,
  general: {
    reactFrameworkColor: '#00D8FF',
    borderRadiusSm: '6px',
    borderRadius: '10px',
    borderRadiusLg: '12px',
    borderRadiusXl: '16px',
  },
  sidebar: {
    background: colors.gradients.primary,
    textColor: colors.layout.sidebar.textColor,
    dividerBg: colors.layout.sidebar.dividerBg,
    menuItemColor: colors.layout.sidebar.menuItemColor,
    menuItemColorActive: colors.layout.sidebar.menuItemColorActive,
    menuItemBg: colors.layout.sidebar.menuItemBg,
    menuItemBgActive: colors.layout.sidebar.menuItemBgActive,
    menuItemIconColor: colors.layout.sidebar.menuItemIconColor,
    menuItemIconColorActive: colors.layout.sidebar.menuItemIconColorActive,
    menuItemHeadingColor: colors.layout.sidebar.menuItemHeadingColor,
    boxShadow: '2px 0 3px rgba(159, 162, 191, .18), 1px 0 1px rgba(159, 162, 191, 0.32)',
  },
  header: {
    height: '80px',
    background: colors.alpha.white[100],
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main,
  },
  spacing: 9,
  palette: {
    common: {
      black: colors.alpha.black[100],
      white: colors.alpha.white[100],
    },
    mode: 'light',
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
    },
    secondary: {
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark,
    },
    error: {
      light: colors.error.light,
      main: colors.error.main,
      dark: colors.error.dark,
      contrastText: colors.alpha.white[100],
    },
    success: {
      light: colors.success.light,
      main: colors.success.main,
      dark: colors.success.dark,
      contrastText: colors.alpha.white[100],
    },
    info: {
      light: colors.info.light,
      main: colors.info.main,
      dark: colors.info.dark,
      contrastText: colors.alpha.white[100],
    },
    warning: {
      light: colors.warning.light,
      main: colors.warning.main,
      dark: colors.warning.dark,
      contrastText: colors.alpha.white[100],
    },
    text: {
      primary: colors.alpha.black[100],
      secondary: colors.alpha.black[70],
      disabled: colors.alpha.black[50],
    },
    background: {
      paper: colors.alpha.white[100],
      default: colors.layout.general.bodyBg,
    },
    action: {
      active: colors.alpha.black[100],
      hover: colors.primary.lighter,
      hoverOpacity: 0.1,
      selected: colors.alpha.black[10],
      selectedOpacity: 0.1,
      disabled: colors.alpha.white[50],
      disabledBackground: colors.alpha.white[20],
      disabledOpacity: 0.4,
      focus: colors.alpha.black[10],
      focusOpacity: 0.05,
      activatedOpacity: 0.12,
    },
    tonalOffset: 0.5,
  },
  breakpoints: {
    values: {
      xs: 475,
      sm: 600,
      md: 991,
      lg: 1199,
      xl: 1399,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          width: '100%',
          height: '100%',
        },
        'body': {
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Inter',
          minHeight: '100%',
          width: '100%',
          flex: 1,
          color: themeColors.white,
          background: lighten(themeColors.primaryAlt, 0),
        },
        '#root': {
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        },
        'html': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },
        'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
          WebkitBoxShadow: '0 0 0 30px #393f5d inset !important',
        },
        'input:-webkit-autofill': {
          WebkitTextFillColor: 'white !important',
        },
        'img': {
          width: '100%',
        },
        '.search-wrap': {
          'display': 'flex',
          'alignItems': 'flex-end',

          '.MuiFormControl-root': {
            margin: 0,
          },
        },
        '::webkitAutofill': {
          backgroundColor: colors.alpha.white[10],
        },
        '.child-popover .MuiPaper-root .MuiList-root': {
          flexDirection: 'column',
        },
        '#nprogress': {
          pointerEvents: 'none',
        },
        '#nprogress .bar': {
          background: colors.primary.lighter,
        },
        '#nprogress .spinner-icon': {
          borderTopColor: colors.primary.lighter,
          borderLeftColor: colors.primary.lighter,
        },
        '#nprogress .peg': {
          boxShadow: '0 0 15px ' + colors.primary.lighter + ', 0 0 8px' + colors.primary.light,
        },
        ':root': {
          '--swiper-theme-color': colors.primary.main,
        },
        '.overTextError, .overTextSuccess': {
          position: 'absolute',
          width: 'calc(100% - 120px)',
          padding: '.5rem 1rem',
          background: colors.error.main,
          color: colors.alpha.white[100],
          left: 5,
          borderRadius: 6,
        },
        '.overTextSuccess': {
          background: colors.success.main,
        },
        'code': {
          background: colors.info.lighter,
          color: colors.info.dark,
          borderRadius: 4,
          padding: 4,
        },
        '.primary': {
          color: colors.primary.main,
        },
        '.white': {
          color: colors.alpha.white[100],
        },
        '.minWidth': {
          '&-50': {
            minWidth: '50px',
          },
          '&-75': {
            minWidth: '75px',
          },
          '&-100': {
            minWidth: '100px',
          },
          '&-125': {
            minWidth: '125px',
          },
          '&-150': {
            minWidth: '150px',
          },
          '&-175': {
            minWidth: '175px',
          },
          '&-200': {
            minWidth: '200px',
          },
          '&-225': {
            minWidth: '225px',
          },
          '&-250': {
            minWidth: '250px',
          },
        },
        '.success': {
          'color': colors.success.main,
          '&Bg': {
            'color': colors.success.main,
            'background': colors.success.bg,
            '&--only': {
              background: colors.success.bg,
            },
          },
          'path': {
            fill: colors.success.main,
          },
        },
        '.info': {
          'color': colors.info.main,
          '&Bg': {
            'color': colors.info.main,
            'background': colors.info.bg,
            '&--only': {
              background: colors.info.bg,
            },
          },
          'path': {
            fill: colors.info.main,
          },
        },
        '.warning': {
          'color': colors.warning.main,
          '&Bg': {
            'color': colors.warning.main,
            'background': colors.warning.bg,
            '&--only': {
              background: colors.warning.bg,
            },
          },
          'path': {
            fill: colors.warning.main,
          },
        },
        '.error': {
          'color': colors.error.main,
          '&Bg': {
            'color': colors.error.main,
            'background': colors.error.bg,
            '&--only': {
              background: colors.error.bg,
            },
          },
          'path': {
            fill: colors.error.main,
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.8)',
            opacity: 0,
          },
        },
        '@keyframes float': {
          '0%': {
            transform: 'translate(0%, 0%)',
          },
          '100%': {
            transform: 'translate(3%, 3%)',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&.MuiBackdrop-invisible': {
            backgroundColor: alpha(darken(themeColors.primaryAlt, 0), 0.25),
            backdropFilter: 'blur(2px)',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginLeft: 8,
          marginRight: 8,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: colors.alpha.black[50],
        },
        icon: {
          top: 'calc(50% - 14px)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
            paddingRight: 12,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.alpha.black[50],
          },
          '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primary.main,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: -5,
          marginBottom: -5,
        },
        title: {},
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          borderRadius: '50%',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          'marginRight': '.5rem',
          'padding': '3px 10px',

          '&.success': {
            'color': colors.success.main,
            '&Bg': {
              color: colors.success.main,
              background: colors.success.bg,
            },
            'path': {
              fill: colors.success.main,
            },
          },
          '&.info': {
            'color': colors.info.main,
            '&Bg': {
              color: colors.info.main,
              background: colors.info.bg,
            },
            'path': {
              fill: colors.info.main,
            },
          },
          '&.warning': {
            'color': colors.warning.main,
            '&Bg': {
              color: colors.warning.main,
              background: colors.warning.bg,
            },
            'path': {
              fill: colors.warning.main,
            },
          },
          '&.error': {
            'color': colors.error.main,
            '&Bg': {
              color: colors.error.main,
              background: colors.error.bg,
            },
            'path': {
              fill: colors.error.main,
            },
          },
        },
        label: {
          fontSize: '1rem',
          padding: 0,
        },
        deleteIcon: {
          'margin': '0 0 0 .5rem',
          'color': colors.error.light,
          '&:hover': {
            color: colors.error.main,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          'boxShadow': 'none',
          '&.Mui-expanded': {
            margin: 0,
          },
          '&::before': {
            display: 'none',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
        colorDefault: {
          background: colors.alpha.black[30],
          color: colors.alpha.white[100],
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        avatar: {
          'background': colors.alpha.black[10],
          'color': colors.alpha.black[70],

          '&:first-of-type': {
            border: 0,
            background: 'transparent',
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        alignItemsFlexStart: {
          marginTop: 0,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {},
        textPrimary: {
          '&.Mui-selected': {
            boxShadow: colors.shadows.primary,
          },
          '&.MuiButtonBase-root:hover': {
            background: colors.alpha.black[5],
          },
          '&.Mui-selected.MuiButtonBase-root:hover': {
            background: colors.primary.main,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          'fontWeight': 'bold',
          'textTransform': 'none',
          'paddingLeft': 16,
          'paddingRight': 16,
          '.MuiSvgIcon-root': {
            transition: 'all .2s',
          },
        },
        startIcon: {
          marginLeft: 0,
        },
        endIcon: {
          alignItems: 'center',
        },
        containedSecondary: {
          backgroundColor: colors.secondary.main,
          color: colors.alpha.white[100],
          border: '1px solid ' + colors.alpha.black[30],
        },
        outlinedSecondary: {
          'backgroundColor': colors.alpha.white[100],

          '&:hover, &.MuiSelected': {
            backgroundColor: colors.alpha.black[5],
            color: colors.alpha.black[100],
          },
        },
        sizeSmall: {
          padding: '.4rem 1rem',
          lineHeight: 1.5,
        },
        sizeMedium: {
          padding: '.5rem 1.25rem',
        },
        sizeLarge: {
          padding: '.75rem 1.5rem',
        },
        textSizeSmall: {
          padding: '.4rem .75rem',
        },
        textSizeMedium: {
          padding: '.5rem 1rem',
        },
        textSizeLarge: {
          padding: '.75rem 1rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root': {
            fill: colors.alpha.white[100],
          },
          '&.Mui-disabled': {
            input: {
              WebkitTextFillColor: colors.alpha.white[70],
              color: colors.alpha.white[70],
            },
          },
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          fontWeight: 400,
          color: colors.alpha.white[100],
          border: '1px solid',
          borderColor: themeColors.primaryLight,
          boxShadow: 'none',
          padding: '0.75rem 1.25rem',
          minWidth: '160px',
        },
        loadingIndicator: {
          top: '50%',
          transform: 'translate(-50%, -50%)',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: {
          'borderRadius': 4,
          'cursor': 'pointer',

          '&.dropdown-input': {
            'display': 'flex',
            'justifyContent': 'space-between',
            'width': 'calc(100% - 35px)',
            'padding': '.75rem 1rem',
            'border': '1px solid',
            'color': colors.alpha.white[100],
            'borderColor': colors.alpha.white[100],
            'background': colors.alpha.white[10],

            '&:hover': {
              background: 'transparent',
            },
            '&Error': {
              borderColor: colors.error.main,
              color: colors.error.main,
            },
          },

          '& ~ .dropdownWidth ': {
            'width': '100%',
            'zIndex': 10,

            '.MuiPaper-root': {
              background: themeColors.dropDownBg,
            },

            '.MuiList-root': {
              padding: '1.25rem 0',
              borderRadius: '.5rem',
              maxHeight: 300,
              overflowY: 'auto',

              li: {
                'display': 'flex',
                'color': colors.alpha.white[100],
                'padding': '0.75rem 1rem',
                'cursor': 'pointer',
                'textAlign': 'initial',

                '.MuiButtonBase-root': {
                  width: 'auto',
                  padding: '0 1rem 0 0',
                  margin: 0,
                },

                '&:hover': {
                  'background': themeColors.primary,

                  '.MuiSvgIcon-root': {
                    color: colors.alpha.white[100],
                  },
                },
              },
            },

            '.dropdown-hidden': {
              position: 'absolute',
              top: '-9999px',
              zIndex: '-10',
            },
          },
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          'color': colors.primary.main,
          'background': colors.alpha.white[100],
          'transition': 'all .2s',

          '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            color: colors.alpha.white[100],
            background: colors.primary.main,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          'width': '100%',
          'marginBottom': '1.5rem',

          '&.dynamic--styling': {
            '.MuiFormLabel-root': {
              transform: 'translate(14px, -9px) scale(0.75)',
            },
            'legend': {
              maxWidth: '100%',
            },
          },
          '&.multiTag': {
            '.MuiButtonBase-root': {
              'marginLeft': '.5rem',
              '&:first-of-type': {
                marginLeft: '1rem',
              },
            },
          },
          'input': {
            color: colors.alpha.white[100],
            padding: 'calc(0.75rem + 1.5px) 1rem',
          },
          'fieldset': {
            borderColor: colors.alpha.white[100],
          },

          '.MuiInputBase-root': {
            'padding': 0,
            'margin': 0,
            'background': colors.alpha.white[10],
            'color': colors.alpha.white[100],
            '& .MuiInputBase-input': {
              padding: 'calc(0.75rem + 1.5px) 1rem',
            },
            '&:hover': {
              background: 'transparent',

              fieldset: {
                borderColor: colors.alpha.white[100],
              },
            },

            '.MuiInputAdornment-root': {
              'margin': '0 0 0 1rem',
              'maxHeight': 'none',

              '.MuiLoadingButton-root': {
                'padding': '.5rem',
                'minWidth': 100,
                'marginRight': 4,
                'borderRadius': 6,

                '&.transparent': {
                  margin: 0,
                },
              },
            },
          },
          '.closeIcon': {
            'position': 'relative',
            'li.dropdownList': {
              paddingRight: '3rem',
            },
            '.MuiIconButton-root': {
              'position': 'absolute',
              'top': '.75rem',
              'right': '1rem',
              'width': 'auto',
              'padding': 0,
              'borderRadius': '50%',

              '&:hover': {
                svg: {
                  fill: colors.error.main,
                },
              },
            },
          },
          '.text': {
            '&-wrapper': {
              'display': 'flex',
              'flexDirection': 'column',
              'border': '1px solid',
              'borderRadius': '0.5rem',
              'input': {
                background: colors.alpha.white[10],
                height: 20,
              },
              'button': {
                background: themeColors.primary,
                color: colors.alpha.white[100],
                border: 0,
                borderRadius: 6,
              },
              '.rdw-colorpicker-modal': {
                left: -175,
              },
              '.rdw-colorpicker-modal-options': {
                overflow: 'auto',
              },
              '.rdw-link-modal, .rdw-embedded-modal, .rdw-emoji-modal, .rdw-image-modal, .rdw-colorpicker-modal': {
                background: themeColors.dropDownBg,
                boxShadow: 'none',
              },
              '.rdw-image-modal': {
                '.rdw-image-modal-header': {
                  margin: 0,
                },
              },
              '.rdw-link-modal': {
                height: 255,
              },
              '.rdw-link-decorator-icon': {
                left: '90%',
                width: '1rem',
                backgroundColor: themeColors.primary,
                padding: 1,
                borderRadius: 3,
              },
              '.rdw-embedded-modal': {
                'height': 200,
                '.rdw-embedded-modal-size': {
                  '& > span': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                },
              },
              '.rdw-editor-main': {
                pre: {
                  background: themeColors.black,
                },
                blockquote: {
                  background: colors.alpha.white[10],
                },
              },
            },
            '&-toolbar': {
              'border': 'none',
              'background': 'transparent',
              'padding': '.5rem .35rem',
              'margin': 0,
              'borderBottom': '1px dashed',
              '.rdw-option-active': {
                background: themeColors.primary,
              },
              '.rdw-option-wrapper': {
                minWidth: '1rem',
                height: '1rem',
              },
              '.rdw-block-dropdown, .rdw-fontsize-dropdown, .rdw-dropdown-wrapper': {
                border: 0,
              },
              '.rdw-dropdown-optionwrapper': {
                'background': themeColors.dropDownBg,

                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  background: colors.alpha.white[10],
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: colors.alpha.white[100],
                  borderRadius: '4px',
                },

                '.rdw-dropdownoption-active, .rdw-dropdownoption-highlighted': {
                  background: themeColors.primary,
                },
              },
              '.rdw-dropdown-wrapper': {
                background: colors.alpha.white[30],
              },
            },
            '&-editor': {
              'height': 275,
              'margin': '.5rem 0',

              '.DraftEditor-root': {
                padding: '0 1rem',
              },
              '.public-DraftStyleDefault-block': {
                margin: 0,
              },
            },
          },
          '&.form-control': {
            'button': {
              fontWeight: 400,
            },
            '.MuiTypography-subtitle2': {
              margin: 0,
            },
          },
          '&.phoneNumber': {
            'input': {
              fontSize: '1rem',
              lineHeight: '1.32rem',
              margin: 0,
              padding: 'calc(0.75rem + 1.5px) 1rem',
              background: colors.alpha.white[10],
              border: '1px solid',
              borderColor: colors.alpha.white[100],
              borderRadius: 8,
            },

            '&Error': {
              input: {
                borderColor: colors.error.main,
                color: colors.error.main,
              },
            },
          },
          '&.has--items': {
            '.MuiButton-text': {
              padding: '.5rem 1rem',
            },
          },
          '.overflowScroll': {
            'overflowX': 'auto',
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-track': {
              background: colors.alpha.white[10],
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: colors.alpha.white[100],
              borderRadius: '4px',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          'color': colors.alpha.white[100],
          'lineHeight': 1,
          'height': '1.5rem',

          '&.Mui-disabled': {
            color: colors.alpha.white[70],
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          'height': 'auto',

          '.MuiButtonBase-root': {
            padding: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          'borderRadius': 8,
          'padding': 8,
          'marginRight': 0,

          '.MuiTouchRipple-root': {
            borderRadius: 8,
          },
          '&.boxCircle': {
            'padding': '0.25rem',
            'background': colors.error.main,
            'borderRadius': '50%',
            'svg': {
              fill: colors.alpha.white[100],
            },
            '&:hover': {
              background: colors.error.bg,
              svg: {
                fill: colors.error.main,
              },
            },
          },
        },
        sizeSmall: {
          padding: 4,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '.MuiTouchRipple-root': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: colors.alpha.white[50],
          border: 0,
          height: 1,
        },
        vertical: {
          'height': 'auto',
          'width': 1,

          '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
            height: 'auto',
          },
          '&.MuiDivider-absolute.MuiDivider-fullWidth': {
            height: '100%',
          },
        },
        withChildren: {
          '&:before, &:after': {
            border: 0,
          },
        },
        wrapper: {
          background: colors.alpha.white[100],
          fontWeight: 700,
          padding: '.5rem',
          lineHeight: 1.5,
          color: 'inherit',
          textTransform: 'uppercase',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '&.header-dropdown': {
            '.MuiButtonBase-root, .MuiBox-root': {
              padding: '1rem 1.25rem',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          'background': themeColors.primaryAlt,
          'padding': 0,
          'minWidth': '200px !important',
          'boxShadow': 'none',

          '&.MuiPopover-paper': {
            background: themeColors.dropDownBg,
          },

          '.MuiBox-root': {
            cursor: 'auto',
          },
        },
        elevation: {
          boxShadow: 'none',
        },
        elevation10: {
          boxShadow: colors.shadows.card,
        },
        elevation24: {
          boxShadow: colors.shadows.card,
        },
        outlined: {
          boxShadow: colors.shadows.card,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: themeColors.white,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '.MuiTypography-root': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          '&.LinkCss': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 400,
            color: colors.alpha.white[100],
            border: '1px solid',
            borderRadius: '10px',
            borderColor: themeColors.primaryLight,
            boxShadow: 'none',
            padding: '0.95rem 1.25rem',
            minWidth: '190px',
            backgroundColor: themeColors.primary,
            margin: 0,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: 6,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '.MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
            transform: 'none',
          },
          '.MuiSlider-valueLabel': {
            borderRadius: 6,
            background: colors.alpha.black[100],
            color: colors.alpha.white[100],
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '.MuiListItem-root': {
            'padding': 0,

            '&:last-child': {
              '.MuiButtonBase-root': {
                marginBottom: 0,
              },
            },
          },
          '.MuiButtonBase-root': {
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'flex-start',
            'width': '100%',
            'color': colors.alpha.white[100],
            'padding': '0.75rem 1rem',
            'marginBottom': '0.5rem',

            'span': {
              cursor: 'pointer',
            },
            '.MuiButton-startIcon': {
              '.MuiSvgIcon-root': {
                fontSize: '1.75rem',
              },
            },

            '&.active': {
              background: themeColors.primary,
            },
          },
          '.MuiListItem-button': {
            'transition': 'all .2s',

            '.MuiSvgIcon-root': {
              minWidth: '2rem',
            },

            '.MuiTouchRipple-root': {
              opacity: 0.2,
            },
          },
        },
        padding: {
          'padding': '.75rem 0',

          '.MuiListItem-button': {
            borderRadius: 6,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root': {
            'display': 'flex',
            'minHeight': 'auto',
            'color': colors.alpha.white[100],
            'border': '1px solid',
            'borderColor': themeColors.primaryLight,
            'borderRadius': 8,
            'transition': 'background .2s',

            '&.Mui-selected': {
              color: colors.alpha.white[100],
              background: themeColors.primary,
            },
          },

          '.MuiTabs-flexContainer': {
            paddingBottom: 4,
            gap: 10,
          },
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {},
        list: {
          'padding': '.75rem 0',

          '.MuiMenuItem-root': {
            'borderRadius': 0,
            'marginBottom': 0,
            'transition': 'all .2s',

            '.MuiTouchRipple-root': {
              opacity: 0.2,
            },

            '&:hover': {
              background: colors.primary.main,
            },

            '&:active, &.active, &.Mui-selected': {
              background: colors.primary.light,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          'background': 'transparent',
          'transition': 'all .2s',

          '&:hover, &:active, &.active, &.Mui-selected': {
            color: colors.alpha.black[100],
            background: alpha(colors.primary.lighter, 0.4),
          },
          '&.Mui-selected:hover': {
            background: alpha(colors.primary.lighter, 0.4),
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            'color': colors.secondary.main,

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.alpha.black[100],
              background: lighten(colors.primary.lighter, 0.5),
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 1,
        },
        root: {
          '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: 14,
          },
        },
        clearIndicator: {
          'background': colors.error.lighter,
          'color': colors.error.main,
          'marginRight': 8,

          '&:hover': {
            background: colors.error.lighter,
            color: colors.error.dark,
          },
        },
        popupIndicator: {
          'color': colors.alpha.black[50],

          '&:hover': {
            background: colors.primary.lighter,
            color: colors.primary.main,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          '&::WebkitScrollbar': {
            width: '6px' /* width of the entire scrollbar */,
          },

          '&::WebkitScrollbarTrack': {
            background: colors.alpha.white[10],
            borderRadius: 4,
          },

          '&::WebkitScrollbarThumb': {
            background: colors.alpha.white[100],
            borderRadius: 4,
          },

          '.MuiTable-root': {
            border: '1px solid',
            borderColor: colors.borderTable[100],
            borderRadius: 14,
            marginBottom: '1.25rem',
            borderCollapse: 'separate',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          'padding': '1rem',
          'color': colors.alpha.white[100],

          '&:last-child': {
            padding: '1rem',
          },

          '.MuiInputBase-root': {
            marginBottom: 0,
          },
        },
        toolbar: {
          '.MuiIconButton-root': {
            'padding': 8,

            '&:hover': {
              background: colors.alpha.white[10],
            },
          },
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
          padding: '0 !important',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          color: colors.alpha.white[100],
        },
        root: {
          'transition': 'background-color .2s',

          '&:last-child': {
            td: {
              borderBottom: 'none',
            },
          },

          '&.MuiTableRow-hover:hover': {
            backgroundColor: colors.alpha.black[5],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: colors.alpha.white[100],
        },
        root: {
          'padding': '1.25rem',
          'borderBottomColor': colors.borderTable[100],
          'color': colors.alpha.white[100],

          '&.detail-items': {
            padding: 0,
          },

          '.MuiButtonBase-root:not(.form-button, .MuiSwitch-switchBase, .boxCircle, .MuiChip-root)': {
            'display': 'flex',
            'minWidth': 'auto',
            'padding': 0,
            'color': colors.alpha.white[100],

            '&.small ': {
              marginRight: '1rem',
            },
            '&.button': {
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 400,
              color: colors.alpha.white[100],
              border: '1px solid',
              borderColor: themeColors.primaryLight,
              boxShadow: 'none',
              padding: '0.75rem 1.25rem',
              minWidth: '160px',
            },
            '&[aria-label="Active"]': {
              color: themeColors.primary,
            },

            '&:last-child': {
              margin: 0,
            },
          },
          '.MuiSvgIcon-root': {},
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            'position': 'relative',
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'center',
            'padding': '1.5rem 1.5rem 2rem',
            'borderRadius': '.75rem',
            'minWidth': 300,
            'background': themeColors.dropDownBg,
            'color': colors.alpha.white[100],

            '.MuiAlert-icon': {
              fontSize: '2.5rem',
              order: 2,
              padding: '0 0 .5rem',
              margin: 'auto',
            },
            '.MuiAlert-message': {
              padding: 0,
              textAlign: 'center',
              order: 3,
            },
            '.MuiAlert-action': {
              padding: 0,
            },
            '.MuiButtonBase-root': {
              marginTop: -8,

              path: {
                fill: colors.alpha.white[100],
              },
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {},
        container: {
          'transition': 'none !important',

          '.MuiPaper-root': {
            color: colors.alpha.white[100],
            maxWidth: 550,
            maxHeight: '75vh',
            margin: 0,
            textAlign: 'center',
            background: colors.gradients.modal,
            backdropFilter: 'blur(50px)',
            transition: 'none',
          },
          '.MuiTypography-root': {
            padding: '1.75rem',
            paddingBottom: 0,
          },
          '.MuiDialogContent-root': {
            'borderTopColor': colors.alpha.white[50],
            'padding': '1.75rem',
            'marginTop': '.75rem',
            'overflow': 'visible',

            '.MuiTypography-root': {
              padding: 0,
            },
          },
          '.MuiDialogActions-root': {
            padding: '1.75rem',
            paddingTop: 0,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {},
        standardInfo: {
          color: colors.info.main,
        },
        action: {
          color: colors.alpha.black[70],
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: 0,
          zIndex: 5,
          position: 'absolute',
          top: '50%',
          marginTop: -6,
          left: -6,
        },
        outlined: {
          backgroundColor: colors.alpha.white[100],
          boxShadow: '0 0 0 6px ' + colors.alpha.white[100],
        },
        outlinedPrimary: {
          backgroundColor: colors.alpha.white[100],
          boxShadow: '0 0 0 6px ' + colors.alpha.white[100],
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          position: 'absolute',
          height: '100%',
          top: 0,
          borderRadius: 50,
          backgroundColor: colors.alpha.black[10],
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          'minHeight': 0,
          'padding': '8px 0',

          '&:before': {
            display: 'none',
          },
        },
        missingOppositeContent: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        popper: {
          '&.custom-color': {
            ' .MuiTooltip-tooltip': {
              color: alpha(colors.alpha.black['100'], 0.95),
              backgroundColor: alpha(colors.alpha.white['100'], 0.95),
            },
          },
        },
        tooltip: {
          backgroundColor: alpha(colors.alpha.black['100'], 0.95),
        },
        arrow: {
          color: alpha(colors.alpha.black['100'], 0.95),
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          'marginRight': '1rem',
          'height': 33,
          'overflow': 'visible',

          '.MuiButtonBase-root': {
            position: 'absolute',
            padding: 6,
            transition: 'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          '.MuiIconButton-root': {
            borderRadius: 100,
          },
        },
        switchBase: {
          '&.Mui-checked': {
            transform: 'translateX(27px)',
          },
        },
        input: {
          width: '200%',
        },
        thumb: {
          border: '1px solid ' + colors.alpha.black[30],
          boxShadow: '0px 9px 14px ' + colors.alpha.black[10] + ', 0px 2px 2px ' + colors.alpha.black[10],
        },
        track: {
          backgroundColor: themeColors.inputBg,
          boxShadow: 'inset 0px 1px 1px ' + colors.alpha.black[10],
          opacity: 1,
        },
        colorPrimary: {
          '.MuiSwitch-thumb': {
            backgroundColor: colors.alpha.white[100],
          },

          '&.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: colors.primary.main,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          background: colors.alpha.black[5],

          svg: {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-completed': {
            color: colors.success.main,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'a',
          subtitle2: 'label',
          body1: 'p',
          body2: 'span',
        },
      },
      styleOverrides: {
        root: {
          'cursor': 'auto',

          '&.status-button': {
            padding: '3px 10px 4px',
            borderRadius: '.5rem',
          },
          '&.custom-color': {
            color: colors.alpha.black[0],
            margin: 0,
          },
          '&.TooltipTextCss': {
            fontWeight: 'bold',
            color: colors.alpha.black[0],
            textAlign: 'left',
            backgroundColor: themeColors.beigeBackGround,
            maxWidth: 'fit-content',
            fontSize: '14px',
          },
        },
        gutterBottom: {
          marginBottom: 4,
        },
        paragraph: {},
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: 'inter, Space Grotesk',
    h1: {
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 1,
      margin: '0 0 1rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.25,
      margin: '0 0 1rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: 2,
      margin: '0 0 1rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5,
      margin: '0 0 1rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.25,
      margin: '0 0 1rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '0.75rem',
      lineHeight: 1.25,
      margin: '0 0 1rem',
    },
    body1: {
      'fontSize': '1rem',
      'fontWeight': 400,
      'lineHeight': 1.5,
      'margin': '0 0 1rem',

      '&.small': {
        fontSize: '.875rem',
      },
    },
    body2: {
      fontSize: '1rem',
      Color: colors.primary,
      fontWeight: 500,
      lineHeight: 1.5,
      textDecoration: 'none',
      margin: '0',
    },
    subtitle1: {
      fontSize: '1rem',
      Color: colors.primary,
      fontWeight: 500,
      lineHeight: 1.5,
      textDecoration: 'none',
      margin: '0',
    },
    subtitle2: {
      display: 'block',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1,
      margin: '0 0 .75rem',
    },
    button: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 400,
      all: 'unset',
      padding: '0.75rem 1.25rem',
      cursor: 'pointer',
    },
    caption: {
      fontSize: '0.875rem',
      textTransform: 'uppercase',
    },
  },
})
