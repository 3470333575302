import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import 'nprogress/nprogress.css'
import App from 'src/App'
import { SidebarProvider } from 'src/contexts/SidebarContext'
import * as serviceWorker from 'src/serviceWorker'
import { queryClient } from 'src/lib/react-query'
import { QueryClientProvider } from '@tanstack/react-query'
import { AuthProvider } from './contexts/AuthContext'
import Providers from './Providers'

ReactDOM.render(
  <HelmetProvider>
    <Providers>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
        <AuthProvider>
          <SidebarProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SidebarProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Providers>
  </HelmetProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
