import { axios } from 'src/lib/axios'
import storage from 'src/utils/storage'
import { User } from 'src/features/auth/types'
import { LoginCredentialsDTO, ResetPasswordDTO, UpdatePasswordDTO } from './types'
import { endpoints } from 'src/utils/endpoints'

export const loginWithEmailAndPassword = async (data: LoginCredentialsDTO) => {
  const resp = await axios.post(endpoints.ADMIN_LOGIN, data)
  storage.setToken(resp.data.jwtToken)

  return {
    email: data.email,
    isTwoFaEnable: resp.data.user.isTwoFaEnable,
  }
}

export const logout = async () => storage.clearToken()

export const getUserIdentity = async (): Promise<User> => {
  let user = null
  if (storage.getToken()) {
    const resp = await axios.get(endpoints.ADMIN_PROFILE)
    user = resp.data
  }
  return user
}

export const resetPassword = (data: ResetPasswordDTO): Promise<any> => {
  return axios.post(endpoints.ADMIN_RESET_PASSWORD, data)
}

export const updatePassword = (data: UpdatePasswordDTO): Promise<any> => {
  return axios.post(endpoints.ADMIN_CHANGE_PASSWORD, data)
}
