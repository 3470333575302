export const paths = {
  login: '/auth/login',
  register: '/auth/register',
  resetPass: '/auth/reset-password',
  forgotPass: '/auth/forgot-password',
  app: '/app',
  profile: '/app/profile',
  twoFaAuthentication: '/app/two-factor-authentication',
  home: '/user',
  addUser: '/user/add-user',
  admin: '/admin',
  addAdmin: '/admin/add-admin',
  threats: '/threats',
  insuranceCompanies: '/insurance-companies',
  addInsuranceCompanies: '/insurance-companies/add-insurance-company',
  insuranceCompanyDetails: '/insurance-companies/details',
  insuranceCompanyAdmin: '/insurance-companies/add-admin',
  incidents: '/incidents',
  policyParameters: '/policy-parameters',
  addPolicyParameter: '/policy-parameters/add-policy-parameter',
  resendVerificationLink: '/auth/resend-verification-link',
  Blogs: '/Blog/add-blog',
  editBlogs: '/Blog/edit-blog',
}
