import { createContext, useMemo, useState } from 'react'
import { useQuery, useMutation, useQueryClient, QueryKey } from '@tanstack/react-query'
import { loginWithEmailAndPassword as loginFn, logout as logoutFn, getUserIdentity } from 'src/lib/auth'
import { User } from 'src/features/auth/types'

interface AuthContextValue {
  user: User | null
  error: Record<string, unknown> | null
  refetchUser: () => void
  login: (payload, value) => void
  isLoggingIn: boolean
  logout: () => void
  isLoggingOut: boolean
}

export const AuthContext = createContext<AuthContextValue | null>(null)
AuthContext.displayName = 'AuthContext'

export interface AuthProviderProps {
  children: React.ReactNode
  authKey?: QueryKey
  waitInitial?: boolean
}

export function AuthProvider(props: AuthProviderProps) {
  const { children, authKey = ['auth-user'], waitInitial = true } = props
  const [user, setUser] = useState<User | null>(null)
  const queryClient = useQueryClient()

  const {
    // data: user,
    error,
    status,
    isLoading,
    fetchStatus,
    isSuccess,
    refetch,
  } = useQuery(authKey, getUserIdentity, {
    onSuccess: data => setUser(data),
  })

  // NOTE: this is not working as intended, not causing a re-render
  //   const setUser = useCallback(
  //     (data) => queryClient.setQueryData(authKey, data),
  //     [queryClient]
  //   );

  const loginMutation = useMutation(loginFn, {
    onSuccess: data => {
      // NOTE: temporay until login method is fixed
      setUser({
        firstName: '',
        lastName: '',
        email: data.email,
        role: 'Super',
        isTwoFaEnable: data.isTwoFaEnable,
      })
    },
  })

  //   const registerMutation = useMutation(registerFn, {
  //     onSuccess: (user) => {
  //       setUser(user);
  //     }
  //   });

  const logoutMutation = useMutation(logoutFn, {
    onSuccess: () => {
      queryClient.clear()
      setUser(null)
    },
  })

  const value = useMemo(
    () => ({
      user,
      error: error as Record<string, unknown> | null,
      refetchUser: refetch,
      login: loginMutation.mutateAsync,
      isLoggingIn: loginMutation.isLoading,
      logout: logoutMutation.mutateAsync,
      isLoggingOut: logoutMutation.isLoading,
      //   register: registerMutation.mutateAsync,
      //   isRegistering: registerMutation.isLoading
    }),
    [
      user,
      error,
      refetch,
      loginMutation.mutateAsync,
      loginMutation.isLoading,
      logoutMutation.mutateAsync,
      logoutMutation.isLoading,
      //   registerMutation.mutateAsync,
      //   registerMutation.isLoading
    ]
  )

  if (isSuccess || !waitInitial) {
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  }

  if (isLoading || fetchStatus === 'idle') {
    return <div>Loading...</div>
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return <div>Something went wrong</div>
  }

  return <div>Unhandled status: {status}</div>
}
