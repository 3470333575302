import { useContext, useEffect } from 'react'
import { List, Button, ListItem, Typography } from '@mui/material'
import SecurityIcon from '@mui/icons-material/Security'
import { NavLink as RouterLink } from 'react-router-dom'
import { SidebarContext } from 'src/contexts/SidebarContext'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import BusinessIcon from '@mui/icons-material/Business'
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { paths } from 'src/configs/paths'
import useAuth from 'src/hooks/useAuth'
import { UserRole, SideMenu } from 'src/utils/enums'
import PostAddIcon from '@mui/icons-material/PostAdd'
import EditNoteIcon from '@mui/icons-material/Edit'

const routes = [
  {
    heading: null,
    children: [
      {
        disabled: false,
        path: paths.insuranceCompanies,
        name: 'Carriers',
        icon: <BusinessIcon />,
      },
      {
        disabled: false,
        path: paths.incidents,
        name: 'Incidents',
        icon: <ReportProblemOutlinedIcon />,
      },
      {
        disabled: false,
        path: paths.home,
        name: 'Policy Holders',
        icon: <PeopleAltOutlinedIcon />,
      },

      {
        disabled: false,
        path: paths.policyParameters,
        name: 'Policy Parameters',
        icon: <SecurityIcon />,
      },
      {
        disabled: false,
        path: paths.Blogs,
        name: 'Add Blog',
        icon: <PostAddIcon />,
      },
      {
        disabled: false,
        path: paths.editBlogs,
        name: 'Blog Listing',
        icon: <EditNoteIcon />,
      },
    ],
  },
  {
    heading: 'Management',
    children: [
      {
        path: paths.threats,
        disabled: false,
        name: 'Incidents Management',
        icon: <TableChartTwoToneIcon />,
      },
      {
        disabled: false,
        path: paths.admin,
        name: 'Admins Management',
        icon: <AccountCircleTwoToneIcon />,
      },
    ],
  },
]

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext)
  const { user, refetchUser } = useAuth()

  useEffect(() => {
    refetchUser()
  }, [refetchUser])

  return (
    <>
      {routes.map((route, outerIdx) => (
        <List
          component="div"
          key={outerIdx}
          subheader={
            route.heading ? (
              <Typography component="h4" sx={{ padding: '0.75rem 1rem' }}>
                {route.heading}
              </Typography>
            ) : null
          }
        >
          {route.children.map((item, innerIdx) => (
            <>
              <ListItem component="div" key={innerIdx}>
                {user.role === UserRole.SUPER ? (
                  <Button component={RouterLink} onClick={closeSidebar} to={item.path} startIcon={item.icon} disabled={item.disabled} disableRipple>
                    <Typography variant="body2">{item.name}</Typography>
                  </Button>
                ) : (
                  item.name !== SideMenu.ADMINS_MANAGEMENT && (
                    <Button component={RouterLink} onClick={closeSidebar} to={item.path} startIcon={item.icon} disabled={item.disabled} disableRipple>
                      {item.name}
                    </Button>
                  )
                )}
              </ListItem>
            </>
          ))}
        </List>
      ))}
    </>
  )
}

export default SidebarMenu
